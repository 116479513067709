"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.YospaceSessionErrorCodes = void 0;
/**
 * Enumeration of Yospace session error codes
 *
 * @see https://developer.yospace.com/sdk-documentation/javascript/api/latest/SessionErrorCode.html
 */
var YospaceSessionErrorCodes;
(function (YospaceSessionErrorCodes) {
    /** The session with the Yospace Central Streaming Manager (CSM) service timed-out.
     No further analytics will be signalled after this event. */
    YospaceSessionErrorCodes[YospaceSessionErrorCodes["TIMEOUT"] = 0] = "TIMEOUT";
    /** The fulfillment payload for an initial VMAP response with placeholder break was not received or did not contain the expected data */
    YospaceSessionErrorCodes[YospaceSessionErrorCodes["UNRESOLVED_BREAK"] = 1] = "UNRESOLVED_BREAK";
    /** The parser returned error(s) during XML parsing */
    YospaceSessionErrorCodes[YospaceSessionErrorCodes["PARSING_ERROR"] = 2] = "PARSING_ERROR";
    /** The result of one or more tracking beacons was unsuccessful. */
    YospaceSessionErrorCodes[YospaceSessionErrorCodes["TRACKING_ERROR"] = 3] = "TRACKING_ERROR";
})(YospaceSessionErrorCodes || (exports.YospaceSessionErrorCodes = YospaceSessionErrorCodes = {}));
