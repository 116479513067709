import { Device, getDevice, getDeviceID } from 'utils/getDevice';
import { getMarketingCloudVisitorID } from 'utils/heartbeatWrapper';
import { getWMUKID } from 'utils/privacy';
import { APP_VERSION } from 'version';

const device = getDevice();

export interface PageInfoState {
  path: string;
  referrer: string;
}

export interface PersistentPropertyType {
  appName: string;
  version: string;
  build: string;
  businessUnit: string;
}

export interface BasePropertyType {
  orientation: 'landscape' | 'portrait';
  pageName: string;
  section: string;
  subsection: string;
  timestamp: string | null;
  url: string;
  path: string;
  referrer: string;
  title: string;
  gameID?: string;
  channel?: string;
  gameState?: string;
}

export interface CustomPropertyType {
  type: string;
  action: string;
  target: string;
  eventName: string;
  [key: string]: string | number | null | undefined;
}

export enum UserStatusAuth {
  LOGGED_IN_REMAINING = 'loggedin:remaining',
  NOT_LOGGED_IN_REMAINING = 'notloggedin:remaining',
  LOGGED_IN_EXPIRED = 'loggedin:expired',
  NOT_LOGGED_IN_EXPIRED = 'notloggedin:expired',
  INITIAL = '',
}

export interface TelemetryUserProps {
  adobeId: string;
  wmukid: string;
  adobeHashID: string;
  deviceID: string;
  kruxId: string; //advertising id.
  mvpd: string;
  bcgId: string | null;
  bcgPlayerStatus: string;
  bcgPlayerType: string | null;
  authStatus: UserStatusAuth;
  externalCampaign: string;
  internalCampaign: string;
  pipVideo: string;
  mvpdMD5: string;
  mvpdDisplayName: string;
  slcPlayerStatus: string;
}

export interface VideoDeliveryPropertyType {
  videoAttribution?: string;
  videoContext: string;
  viewMode: 'inline' | 'fullscreen';
  contentType: string;
  alertType?: string;
  adTimeElapsed?: string;
  affiliate: string;
  authState?: 'preview' | 'authenticated' | '';
  authRequired: 'Logged in' | 'Not Logged in';
  cmsName: 'mml';
  videoSessionId?: string;
}

export type VideoPropertyType = {
  AV: 'video';
  contentId: string;
  videoTitleId: string;
  awayTeam?: string | undefined;
  homeTeam?: string | undefined;
  broadcaster?: string;
  network?: string;
  appVersion: string;
  mobileDevice: string;
  appName: string;
  videoAttribution?: string | undefined;
  videoContext: string;
  viewMode: 'inline' | 'fullscreen' | 'primary' | 'secondary' | 'hidden' | 'double_box_ad_primary';
  contentType: string;
  alertType?: string;
  adTimeElapsed?: string;
  affiliate: string;
  authState?: 'preview' | 'authenticated' | '';
  authRequired: 'Logged in' | 'Not Logged in';
  cmsName: 'mml';
  videoSessionId?: string;
  adobeID: string;
  wmukid: string;
  adobeHashID: string;
  kruxId: string; //advertising id.
  mvpdId: string;
  bcgId: string | undefined;
  advertisingId: string | undefined;
  campaignId: string | undefined;
};

export const VideoProperties: VideoPropertyType = {
  AV: 'video',
  contentId: '',
  videoTitleId: '',
  appVersion: APP_VERSION.version,
  mobileDevice: 'Settop',
  appName: '',
  videoContext: '',
  viewMode: 'inline',
  contentType: '',
  affiliate: '',
  authRequired: 'Not Logged in',
  cmsName: 'mml',
  adobeID: getMarketingCloudVisitorID(),
  adobeHashID: '',
  wmukid: getWMUKID(),
  kruxId: getDeviceID(),
  mvpdId: '',
  bcgId: '',
  advertisingId: '',
  campaignId: '',
};

export interface AnalyticsType {
  persistentProperties: PersistentPropertyType;
  baseProperties: BasePropertyType;
  userProperties: TelemetryUserProps;
}

export const PAGE_INFO_STATE_INTIAL: PageInfoState = {
  path: '',
  referrer: '',
};

export const PersistentProperties: PersistentPropertyType = {
  appName: `app:watchmml - ${device === Device.lgtv ? 'lg tv' : device}`,
  version: APP_VERSION.version,
  build: '',
  businessUnit: 'NCAA',
};

export const BaseProperties: BasePropertyType = {
  orientation: 'landscape',
  pageName: '',
  section: '',
  subsection: '',
  timestamp: null,
  url: '',
  path: '',
  referrer: '',
  title: '',
};

export const CustomProperties: CustomPropertyType = {
  type: '',
  action: '',
  target: '',
  eventName: '',
  ...BaseProperties,
};

export const UserProperties: TelemetryUserProps = {
  adobeId: getMarketingCloudVisitorID(),
  wmukid: getWMUKID(),
  adobeHashID: '',
  deviceID: getDeviceID(),
  kruxId: getDeviceID(),
  mvpd: '',
  mvpdDisplayName: '',
  bcgPlayerStatus: 'false',
  bcgId: null,
  bcgPlayerType: null,
  authStatus: UserStatusAuth.INITIAL,
  externalCampaign: '',
  internalCampaign: '',
  pipVideo: 'false',
  mvpdMD5: '',
  slcPlayerStatus: 'false',
};
