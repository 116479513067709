import { PerformanceBucket } from 'types/configs';
import { Device, getDevice, getDeviceCaptionsEnabled } from 'utils/getDevice';
import { isWebos4 } from 'utils/webos/webos-utils';

class PerformanceFeatureFlagUtil {
  private _device: Device;
  private _performanceBucket: PerformanceBucket;
  private _performanceFlags: PerformanceFlags | undefined;
  private _webosDeviceInfo: DeviceInfo | undefined;

  constructor(device: Device, _performanceFlags?: PerformanceFlags) {
    this._device = device;
    this._performanceFlags = _performanceFlags;
    this._performanceBucket = PerformanceBucket.HighEnd;

    // pre fetch and cache the device captions setting on lg
    if (this._device === Device.lgtv) {
      getDeviceCaptionsEnabled();
    }
  }

  set performanceBucket(value: PerformanceBucket) {
    this._performanceBucket = value;
  }

  get performanceBucket() {
    return this._performanceBucket;
  }

  set performanceFlags(value: PerformanceFlags) {
    this._performanceFlags = value;
  }

  set webosDeviceInfo(value: DeviceInfo | undefined) {
    this._webosDeviceInfo = value;
  }

  shouldAllowSkeletonLoadingAnimation(): boolean {
    if (this._device === Device.lgtv) {
      return !this._isLowEndWebosDevice();
    } else {
      const disableSkeletonLoading = !!this._getFlagsForDevice()?.disableSkeletonLoading;
      return !disableSkeletonLoading;
    }
  }

  shouldAllowPulseIconAnimation(): boolean {
    if (this._device === Device.lgtv) {
      return !this._isLowEndWebosDevice();
    } else {
      return !this._getFlagsForDevice()?.disablePulseIconAnimation;
    }
  }

  shouldAllowPrism(): boolean {
    if (this._device !== Device.lgtv) return true;

    return !this._isLowEndWebosDevice();
  }

  shouldUseLowerBitrate(): boolean {
    return true;
  }

  getMaxBitrate(): number | null {
    if (this._device === Device.lgtv && this._isLowEndWebosDevice()) {
      return 2500;
    } else {
      if (this._device === Device.xbox || this._device === Device.lgtv) {
        // TODO:  temporarily allowing these to be unlimited in the absence of having this be config driven
        // for us to be able to dynamically control the max bitrate on individual device models.  Should update this
        // for 2024 to actually be config driven.
        return null;
      } else if (this._device === Device.firetv) {
        const maxBitrate = this._getFlagsForDevice()?.maxBitrate;
        // Allowing firetv to have an uncapped bitrate as part of an experiment
        // Not trying to make the logic too pretty to keep it simple/reduce any chance of screwing up the logic
        return maxBitrate || null;
      } else {
        const maxBitrate = this._getFlagsForDevice()?.maxBitrate;
        // 5500 is currently the highest bitrate before we reach 60fps streams
        return maxBitrate || 5500;
      }
    }
  }

  getMultigameMaxBitrate(): number | null {
    const maxBitrate = this._getFlagsForDevice()?.multigameMaxBitrate;

    // Default max bitrate to 2000 Kbps when multigame is active
    return maxBitrate || 2000;
  }

  shouldLimitFreeViewUpdateBroadcasts(): boolean {
    return this._device === Device.lgtv && this._isLowEndWebosDevice();
  }

  shouldAllowMagicRemote(): boolean {
    return this._device === Device.lgtv && !this._isLowEndWebosDevice();
  }

  shouldAllowGameCenterPollingHack(): boolean {
    return this._device === Device.lgtv && this._isLowEndWebosDevice();
  }

  private _getFlagsForDevice(): PerformanceFlagOptions | undefined {
    return this._performanceFlags?.[this._performanceBucket];
  }

  private _isLowEndWebosDevice(): boolean {
    return isWebos4(this._webosDeviceInfo);
  }
}

const performance: PerformanceFlags = {
  [PerformanceBucket.LowEnd]: {
    maxBitrate: 2000,
    multigameMaxBitrate: 1000,
    disableSkeletonLoading: true,
    disablePulseIconAnimation: true,
  },
  [PerformanceBucket.Average]: {
    maxBitrate: null,
    multigameMaxBitrate: null,
    disableSkeletonLoading: false,
    disablePulseIconAnimation: false,
  },
  [PerformanceBucket.HighEnd]: {
    maxBitrate: null,
    multigameMaxBitrate: null,
    disableSkeletonLoading: false,
    disablePulseIconAnimation: false,
  },
};

type PerformanceFlagOptions = {
  maxBitrate: number | null;
  multigameMaxBitrate: number | null;
  disableSkeletonLoading: boolean;
  disablePulseIconAnimation: boolean;
};

type PerformanceFlags = {
  [key in PerformanceBucket]: PerformanceFlagOptions;
};

const performanceFeatureFlagUtil = new PerformanceFeatureFlagUtil(getDevice(), performance);

export { performanceFeatureFlagUtil as PerformanceFeatureFlagUtil };

/**
 * putting this here for reference
 *
 * lowEndModelsAndroid = 'Bravia 4K GB,Bravia 4K GB ATV3,Bravia VU1,Beyond TV,Bravia 4K 2015,Smart TV Pro,Stream 4K,2K TV Stick'
 * lowEndModelsFire = 'AFTSSS,AFTSS,AFTKA,AFTEAMR311,AFTKMST12,Fire TV (3rd Gen),AFTJMST12,AFTA,AFTB,Fire TV Stick,AFTRS,AFTT,AFTM,AFTLFT962X3,AFTS,AFTN'
 */
