import { Monitor as monitor } from 'utils/monitor';
import { useCallback } from 'react';
import {
  AuthErrorNumeral,
  type AuthError,
  type AuthorizationDataResponse,
  type ClientlessAuthEngine,
  type PresentRegCodeResult,
  type Provider,
} from '@top/auth-clientless-web-lite';
import { MMLChannel, useAuthConfigBuilder } from './useAuthConfigBuilder';
import { logger } from 'logger';
import { ReactiveVar, makeVar, useReactiveVar } from '@apollo/client';
import { AuthState, AuthStateNames, AUTH_STATE_INITIAL } from './auth-states';
import { TelemetryUserProps, UserStatusAuth } from 'state-machines/analytic-states';
import md5 from 'md5';
import { AppMessage, BillboardKeys } from '../../Message/message-commander';
import { TVE } from './tve-stores';
import {
  basePropertyStore,
  freeviewStateStore,
  isConnectingStore,
  tvAuthRegCodeStore,
  userPropertyStore,
} from 'apolloGraphql/mml-apollo-cache';
import { FreeViewStateNames } from './freeview-states';
import { AnalyticsUtil } from 'utils/AnalyticsUtil';
import { getMarketingCloudVisitorID } from 'utils/heartbeatWrapper';

const LOG = logger.getLogger('useAuthWrapper');

declare global {
  interface Window {
    Auth: ClientlessAuthEngine;
  }
}

export type TveLogos = {
  largeLogo?: string;
  smallLogoBlack?: string;
  smallLogoWhite?: string;
  smallLogoGameCenter?: string;
};

interface AuthProps {
  initAuth: () => Promise<AuthState>;
  login: () => Promise<AuthState>;
  logout: () => void;
  getAuthToken: (channel: MMLChannel) => Promise<Partial<AuthorizationDataResponse>>;
  authStateMachine: AuthState;
}

export const authStateMachineStore: ReactiveVar<AuthState> = makeVar<AuthState>(AUTH_STATE_INITIAL);
let authCreated = false;

// TODO: useReactiveVar in here takes a noticeable amount of time (Shell -> useAnalytics -> useAuthWrapper)
export const useAuthWrapper = (): AuthProps => {
  const authConfigPromise = useAuthConfigBuilder();
  const authStateMachine = useReactiveVar(authStateMachineStore);
  const freeViewState = useReactiveVar(freeviewStateStore);

  const authGuard = () => {
    if (TVE.authLib == null) {
      LOG.error('Auth lib has not been initialized');
    }
  };

  const initAuth = useCallback(async (): Promise<AuthState> => {
    LOG.info('initAuth');
    const authConfig = await authConfigPromise;
    if (authCreated || !authConfig) {
      return Promise.resolve(authStateMachine);
    }

    // Delay loading lib until after app launch
    const { ClientlessAuthEngine } = await import(/* webpackChunkName: "TopAuth" */ '@top/auth-clientless-web-lite');

    self.Auth = TVE.authLib = new ClientlessAuthEngine(await authConfig);
    authCreated = true;

    try {
      const response = await TVE.authLib.prepare();
      LOG.info('Auth lib init successful. Auth context:', response);
      const authState: AuthState = { state: AuthStateNames.UNAUTHENTICATED };

      if (response) {
        if (response.isAuthenticated) {
          authState.provider = response.provider;
          authState.state = AuthStateNames.AUTHENTICATED;
          authState.userProperties = {
            authStatus: UserStatusAuth.LOGGED_IN_REMAINING,
            mvpdDisplayName: response.provider?.displayName ?? '',
            mvpd: response.provider?.id ?? '',
            mvpdMD5: response.provider ? md5(response.provider.id.toLowerCase()) : '',
            adobeHashID: response.adobeHashId ?? '',
            adobeId: getMarketingCloudVisitorID(),
          };

          if (authState.provider) {
            const logos = await getProviderLogos(authState.provider);
            authState.logos = logos;
          }
          authState.initializing = false;
        }
      }
      if (authState.userProperties) updateUserProperties(authState.userProperties);
      authStateMachineStore(authState);
      return authState;
    } catch (e) {
      LOG.error('Auth lib failed to init successfully!');
      // throw e;
      return Promise.resolve(authStateMachine);
    }
  }, [authConfigPromise, authStateMachine]);

  const login = useCallback(async (): Promise<AuthState> => {
    authGuard();

    const presentRegCodeCallback = (result: PresentRegCodeResult) => {
      const { registrationCode, registrationURL } = result;
      LOG.info(`Log in at ${registrationURL} with reg code "${registrationCode}"`);
      tvAuthRegCodeStore(registrationCode as string);
    };

    try {
      const response = await TVE.authLib.login(presentRegCodeCallback);
      const authState: AuthState = { state: AuthStateNames.UNAUTHENTICATED };
      LOG.info('[login] context from auth:', response);
      if (response.provider != null && response.isAuthenticated) {
        isConnectingStore(true);
        authState.provider = response.provider;
        authState.state = AuthStateNames.AUTHENTICATED;
        authState.userProperties = {
          authStatus:
            freeViewState === FreeViewStateNames.USING_FREEVIEW
              ? UserStatusAuth.LOGGED_IN_REMAINING
              : UserStatusAuth.LOGGED_IN_EXPIRED,
          mvpdDisplayName: response.provider?.displayName ?? '',
          mvpd: response.provider?.id ?? '',
          mvpdMD5: response.provider ? md5(response.provider.id.toLowerCase()) : '',
          adobeHashID: response.adobeHashId ?? '',
          adobeId: getMarketingCloudVisitorID(),
        };
        const tveLogos = await getProviderLogos(response.provider);
        LOG.info('Provider Logos:', tveLogos);
        authState.logos = tveLogos;
        updateUserProperties(authState.userProperties);
        authStateMachineStore(authState);
        AnalyticsUtil.trackEvent({
          ...basePropertyStore(),
          type: `tve`,
          action: `signin`,
          eventName: 'tve_login_complete',
          target: 'login_complete',
        });
      }
      return authState;
    } catch (error) {
      const loginError: AuthError = error as AuthError;
      LOG.warn('Login failed: ', loginError);
      if (loginError.code?.slice(-4) !== AuthErrorNumeral.AuthenticationTimeout) {
        // throw error;
        AppMessage.show(BillboardKeys.TVE_GENERIC_AUTH_ERROR);
      }
      tvAuthRegCodeStore(undefined);
    } finally {
      isConnectingStore(false);
    }
    return Promise.resolve(authStateMachine);
  }, [authStateMachine, freeViewState]);

  const getProviderLogos = async (provider: Provider): Promise<TveLogos> => {
    const largeLogo = await TVE.authLib.buildImageURL({
      context: 'cobranding',
      providerId: provider.id,
      color: 'white',
      width: 589,
      height: 72,
    });

    const smallLogoBlack = await TVE.authLib.buildImageURL({
      context: 'cobranding',
      providerId: provider.id,
      color: 'black',
      width: 238,
      height: 30,
    });

    const smallLogoWhite = await TVE.authLib.buildImageURL({
      context: 'cobranding',
      providerId: provider.id,
      color: 'white',
      width: 238,
      height: 30,
    });

    const smallLogoGameCenter = await TVE.authLib.buildImageURL({
      context: 'cobranding',
      providerId: provider.id,
      color: 'white',
      width: provider.id === 'ATT' ? 130 : 238, // to account for lack of top/bottom padding
      height: 30,
    });

    return { largeLogo, smallLogoBlack, smallLogoWhite, smallLogoGameCenter };
  };

  const logout = (): void => {
    authGuard();
    tvAuthRegCodeStore(undefined);
    const authState: AuthState = { state: AuthStateNames.UNAUTHENTICATED };
    authState.userProperties = {
      authStatus:
        freeViewState === FreeViewStateNames.USING_FREEVIEW
          ? UserStatusAuth.NOT_LOGGED_IN_REMAINING
          : UserStatusAuth.NOT_LOGGED_IN_EXPIRED,
      mvpd: '',
      mvpdMD5: '',
      adobeHashID: '',
    };
    updateUserProperties(authState.userProperties);
    authStateMachineStore(authState);
    AnalyticsUtil.trackEvent({
      ...basePropertyStore(),
      type: `tve`,
      action: `signout`,
      eventName: 'settings_tve_signout',
      target: 'signout_complete',
    });

    TVE.authLib.logout();
  };

  const getAuthToken = useCallback(
    async (channel: MMLChannel): Promise<Partial<AuthorizationDataResponse | Record<string, never>>> => {
      LOG.info('getAuthToken channel: ', channel);
      authGuard();

      try {
        const authZResult = await TVE.authLib.authorize(channel);
        LOG.info('Authorize successful:', authZResult);
        return authZResult;
      } catch (e) {
        const error: AuthError = e as AuthError;
        LOG.warn('Not authorized - auth error>', error);
        const errorMessage = `AUTH_ERROR code: ${error.code} message: ${error.message}`;
        monitor.sendError(errorMessage);
        AppMessage.show(BillboardKeys.TVE_AUTHZ_ERROR, channel);
        // throw e;
        return Promise.resolve({});
      }
    },
    [],
  );
  return { initAuth, login, logout, getAuthToken, authStateMachine };
};

function updateUserProperties(params: Partial<TelemetryUserProps>) {
  const updatedProperties = Object.entries(params).reduce<Partial<TelemetryUserProps>>(
    (previousValue, [key, value]) => {
      if (value !== undefined) {
        if (key === 'authStatus') previousValue.authStatus = value as UserStatusAuth;
        else previousValue[key as keyof Omit<TelemetryUserProps, 'authStatus'>] = value as string;
      }
      return previousValue;
    },
    {},
  );
  userPropertyStore({ ...userPropertyStore(), ...updatedProperties });
}
