import { StaticTestEnv } from 'types/generated-types';

export interface AppConfig {
  app: App;
  bcg: Bcg;
  environments: Environment[];
  feature_endpoints: FeatureEndpoints;
  fp: FreePreviewConfig;
  game_event_center: GameEventCenter;
  gql: GqlSettings;
  image_endpoints: ImageEndpoints;
  modified_prerolls: ModifiedPrerolls;
  official_bracket: BracketFallback;
  performance: PerformanceConfig;
  states: PreSelectionStates;
  video: VideoSettings;
  web_endpoints: WebEndpoints;
}

interface App {
  feedback_email: string;
  sponsors: string;
  last_updated: string;
  latest_version: string;
  mandatory_version: string;
  appstore_url: string;
}

export interface Environment {
  name: string;
  default: boolean;
  url: string;
  description: string;
}

interface PreSelectionStates {
  pre_selection_sunday: PreSelectionSunday;
}

interface PreSelectionSunday {
  title: string;
  start_date_time: number;
}

interface Bcg {
  logos: Logo[];
}

interface Logo {
  type: string;
  href: string;
  title: string;
  description: string;
}

interface BracketFallback {
  fallback: BracketWebView;
}

/**
 * each PerformanceBucket is a comma separated list of device Model Names
 */
export type PerformanceConfig = {
  [key in PerformanceBucket]: string;
};

interface BracketWebView {
  enable_webview: boolean;
  href: string;
}

interface GameEventCenter {
  buttons: Button[];
  gameswitcher: GameSwitcherEvent[];
}

interface Button {
  type: string;
  title: string;
}

interface GameSwitcherEvent {
  events: number[];
  type: string;
  title: string;
  description: string;
}

interface ModifiedPrerolls {
  logos: ModifiedPrerollLogos[];
}

export interface ModifiedPrerollLogos {
  type: string;
  href: string;
  title: string;
  description: string;
}

// TODO:  probably should refactor these individually named interfaces into a single "ImageEndpoint" interface
interface ImageEndpoints {
  appsponsor_logos: AppsponsorLogos;
  appsponsor_loadingscreen_logos: AppsponsorLoadingscreenLogos;
  broadcast_logos: BroadcastLogos;
  qrcode: QRCode;
  qrcode_tve: QRCode;
  qrcode_pre: QRCode;
  qrcode_cbs: QRCode;
  multigame_sponsor: MultigameSponsor;
  multigame_brandedslate: MultigameBrandedslate;
  finalfour_logo: FinalfourLogo;
  livesponsor_logos: LivesponsorLogos;
  officialbracket_sponsor_logos: OfficialbracketSponsorLogos;
  team_logos: TeamLogos;
  event_marquee: EventMarquee;
  event_gameswitcher_videocontrols: EventGameswitcherVideocontrols;
  event_gameswitcher_gamecenter: EventGameswitcherGamecenter;
  montagesponsor_eventcenter: MontageSponsor;
  live_doublebox_slate: LiveDoubleboxSlate;
}

interface AppsponsorLogos {
  href: string;
  poll_ttl: number;
}

interface AppsponsorLoadingscreenLogos {
  href: string;
  poll_ttl: number;
}

interface BroadcastLogos {
  href: string;
  poll_ttl: number;
}

interface QRCode {
  href: string;
  poll_ttl: number;
}
interface MultigameSponsor {
  href: string;
  poll_ttl: number;
}
interface MultigameBrandedslate {
  href: string;
  poll_ttl: number;
}
interface FinalfourLogo {
  href: string;
  poll_ttl: number;
}

interface LivesponsorLogos {
  href: string;
  poll_ttl: number;
}

interface OfficialbracketSponsorLogos {
  href: string;
  poll_ttl: number;
}

interface TeamLogos {
  href: string;
  poll_ttl: number;
}

interface EventMarquee {
  href: string;
  poll_ttl: number;
}

interface EventGameswitcherVideocontrols {
  href: string;
  poll_ttl: number;
}

interface EventGameswitcherGamecenter {
  href: string;
  poll_ttl: number;
}

interface MontageSponsor {
  href: string;
  poll_ttl: number;
}

interface LiveDoubleboxSlate {
  href: string;
  poll_ttl: number;
}

interface FeatureEndpoints {
  billboards: Billboards;
  ads_config: AdsConfig;
  app_controls: AppControls;
  bcg_default: BcgDefault;
  bcg_picks: BcgPicks;
  bcg_auth: BcgAuth;
  bcg_auth_status: BcgAuthStatus;
  bcg_celebritysearch: BcgCelebritysearch;
  bcg_bracketsearch: BcgBracketsearch;
  bcg_show: BcgShow;
}

interface Billboards {
  href: string;
  poll_interval: number;
}

interface AdsConfig {
  href: string;
  poll_interval: number;
}

interface AppControls {
  href: string;
  poll_interval: number;
}

interface BcgDefault {
  href: string;
  poll_interval: number;
}

interface BcgPicks {
  href: string;
  poll_interval: number;
}

interface BcgAuth {
  href: string;
  poll_interval: number;
}

interface BcgAuthStatus {
  href: string;
  poll_interval: number;
}

interface BcgCelebritysearch {
  href: string;
  poll_interval: number;
}

interface BcgBracketsearch {
  href: string;
  poll_interval: number;
}

interface BcgShow {
  href: string;
  poll_interval: number;
}

interface WebEndpoints {
  ad_choices: SingleWebEndpoint;
  bcg_login: SingleWebEndpoint;
  bcg_profile: SingleWebEndpoint;
  do_not_sell: SingleWebEndpoint;
  faq: SingleWebEndpoint;
  privacy_policy: SingleWebEndpoint;
  terms: SingleWebEndpoint;
  tve_secondscreen_auth: SingleWebEndpoint;
  us_state_supplement: SingleWebEndpoint;
  ways_to_watch: SingleWebEndpoint;
}

interface SingleWebEndpoint {
  href: string;
  style: WebEndpointStyle;
}

export enum PerformanceBucket {
  HighEnd = 'high-end',
  Average = 'average',
  LowEnd = 'low-end',
}

export enum WebEndpointStyle {
  Internal = 'internal',
  External = 'external',
}

interface GqlSettings {
  season_year: string;
  static_test_env: StaticTestEnv;
  host: string;
  links: Links;
}

interface Links {
  home_featured: HomeFeatured;
  scores_bracket: ScoresBracket;
  official_bracket: OfficialBracket;
  scores_current: ScoresCurrent;
  gamecenter_seasons_average: GamecenterSeasonsAverage;
  gamecenter_game_stats: GamecenterGameStats;
  gamecenter_event: GamecenterEvent;
  timemachine: TimeMachine;
}

interface GqlLink {
  query: string;
  poll_interval: number;
}

type ScoresBracket = GqlLink;

type OfficialBracket = GqlLink;

type ScoresCurrent = GqlLink;

type GamecenterSeasonsAverage = GqlLink;

type GamecenterGameStats = GqlLink;

type GamecenterEvent = GqlLink;

type HomeFeatured = GqlLink;

type TimeMachine = GqlLink;

export interface VideoSettings {
  ad_container: AdContainer;
  app_id: string;
  live: Live;
  vod: Vod;
  auth: Auth;
  montage: Montage;
  free_preview: FreePreview;
}

interface AdContainer {
  id: string;
  width: number;
  height: number;
  fullscreen_width: number;
  fullscreen_height: number;
}

interface AdProfiles {
  /**
   *  Indicates the SSAI ad profile to use for live games
   */
  game: string;
  /**
   * Indicates the SSAI ad profile to use for fastbreak
   */
  fastbreak: string;
  /**
   * Indicates the SSAI ad profile to use for games with excitement alerts
   */
  excitement_alert: string;
}

interface Live {
  env: string;
  ad_profiles: AdProfiles;
  comp_id: string;
  access_token_type: string;
  auth_type: string;
  token_url: string;
  preroll_separate_mediaid: string;
}

interface Vod {
  env: string;
  ad_profile: string;
}

interface Auth {
  env: string;
  mvpd_config_url: string;
  help_url: string;
  client_config: string;
  brand: string;
}

interface FreePreview {
  env: string;
  year: string;
}

interface Montage {
  env: string;
  ad_profiles: MontageAdProfiles;
}

interface MontageAdProfiles {
  [key: number]: string | undefined;
}

interface FreePreviewConfig {
  video_controls_cta: string; // Text for Video Controls cta button
  time: number; // Representation of amount of maximum free preview time
  access_billboard: AccessBillboard; // Config for Get Access modals
  ways_to_watch: AccessConfig[]; // The access configurations for Ways To Watch
}

export interface AccessBillboard {
  title_fp_not_ended: string; // Title Text for modal when free preview is available
  title_fp_ended: string; // Title Text for modal when free preview is expired
  TBS: AccessConfig[]; // The access configurations for TBS broadcaster
  TNT: AccessConfig[]; // The access configurations for TNT broadcaster
  truTV: AccessConfig[]; // The access configurations for truTV broadcaster
  CBS: AccessConfig[]; // The access configurations for CBS broadcaster
  HLN: AccessConfig[]; // The access configurations for HLN broadcaster
}

export enum CtaType {
  TVE = 'tve',
  DeepLink = 'deeplink',
}

export interface AccessConfig {
  title: string; // Short title text for this get access option
  description: string; // Long description text for this get access option
  settings_description: string; // Long description text for use on the no deeplink settings page
  logo: string; // Logo representation of this get access option
  app_id: string; // App Id needed for CTA on certain platforms
  cta_text: string; // CTA text of this get access option
  cta_type: CtaType; // Type of access option. One of "tve" or "deeplink"
  cta_url: string; // CTA Url of this get access option
}
