export enum StatsSectionStateNames {
  INACTIVE = 'INACTIVE',
  UPCOMING = 'UPCOMING',
  UPCOMING_INTERRUPTED = 'UPCOMING_INTERRUPTED',
  PRE_GAME = 'PRE_GAME',
  PRE_GAME_INTERRUPTED = 'PRE_GAME_INTERRUPTED',
  LIVE = 'LIVE',
  LIVE_BLACKOUT = 'LIVE_BLACKOUT',
  LIVE_INTERRUPTED = 'LIVE_INTERRUPTED',
  FINAL = 'FINAL',
  UNPLAYED_FINAL = 'UNPLAYED_FINAL',
}

export type StatsSectionState = {
  id: string;
  canShow: boolean;
  title: string;
  data: string;
};

enum StatsSectionStates {
  empty,
  pre,
  live,
  final,
}

interface StatsSectionStateValues {
  title: string;
  data: string;
}

function getStatsSectionState(statState: StatsSectionStates): StatsSectionStateValues {
  switch (statState) {
    case StatsSectionStates.empty:
      return { title: '', data: '' };
    case StatsSectionStates.pre:
      return { title: 'SEASON AVERAGES', data: 'season averages' };
    case StatsSectionStates.live:
      return { title: 'LIVE STATS', data: 'live stats' };
    case StatsSectionStates.final:
      return { title: 'GAME STATS', data: 'game stats' };
  }
}

export const StatSectionStates: { [state in StatsSectionStateNames]: StatsSectionState } = {
  INACTIVE: {
    id: StatsSectionStateNames.INACTIVE,
    canShow: false,
    ...getStatsSectionState(StatsSectionStates.empty),
  },
  UPCOMING: {
    id: StatsSectionStateNames.UPCOMING,
    canShow: true,
    ...getStatsSectionState(StatsSectionStates.pre),
  },
  UPCOMING_INTERRUPTED: {
    id: StatsSectionStateNames.LIVE_INTERRUPTED,
    canShow: true,
    ...getStatsSectionState(StatsSectionStates.live),
  },
  PRE_GAME: {
    id: StatsSectionStateNames.PRE_GAME,
    canShow: true,
    ...getStatsSectionState(StatsSectionStates.pre),
  },
  PRE_GAME_INTERRUPTED: {
    id: StatsSectionStateNames.LIVE_INTERRUPTED,
    canShow: true,
    ...getStatsSectionState(StatsSectionStates.live),
  },
  LIVE: {
    id: StatsSectionStateNames.LIVE,
    canShow: true,
    ...getStatsSectionState(StatsSectionStates.live),
  },
  LIVE_BLACKOUT: {
    id: StatsSectionStateNames.LIVE_BLACKOUT,
    canShow: false,
    ...getStatsSectionState(StatsSectionStates.empty),
  },
  LIVE_INTERRUPTED: {
    id: StatsSectionStateNames.LIVE_INTERRUPTED,
    canShow: true,
    ...getStatsSectionState(StatsSectionStates.live),
  },
  FINAL: {
    id: StatsSectionStateNames.FINAL,
    canShow: true,
    ...getStatsSectionState(StatsSectionStates.final),
  },
  UNPLAYED_FINAL: {
    id: StatsSectionStateNames.UNPLAYED_FINAL,
    canShow: true,
    ...getStatsSectionState(StatsSectionStates.pre),
  },
};
