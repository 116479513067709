"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SetupConfigBuilder = void 0;
var PlayConfigBuilder_1 = require("./PlayConfigBuilder");
/**
 * Builder to create a config data object which defines information to initialize the player
 *
 * @see [[ISharedPlayer.setup]]
 */
var SetupConfigBuilder = /** @class */ (function () {
    /** @hidden */
    function SetupConfigBuilder() {
        this._setupConfigData = {
            config: {},
        };
        this._playConfigBuilder = PlayConfigBuilder_1.PlayConfigBuilder.create();
    }
    /**
     * Create a builder instance
     * @hidden
     * @return The builder instance
     */
    SetupConfigBuilder.create = function () {
        return new SetupConfigBuilder();
    };
    /**
     * Specify the container the video element gets attached to. Specifying
     * this will automatically attach video element
     *
     * @see [[ISharedPlayer.attach]]
     */
    SetupConfigBuilder.prototype.withContainer = function (container) {
        this._setupConfigData.container = container;
        return this;
    };
    /**
     * Specify data for the various player features
     */
    SetupConfigBuilder.prototype.withFeatures = function (features) {
        this._playConfigBuilder.withFeatures(features);
        return this;
    };
    /**
     * Specify data to configure the analytics section of configuration.
     *
     * @param value whether we should suppress ad reporting or not.
     * @see {@link PlayerConfigAnalyticsData}
     */
    SetupConfigBuilder.prototype.withSuppressAdAnalytics = function (value) {
        this._playConfigBuilder.withSuppressAdAnalytics(value);
        return this;
    };
    /**
     * Specify data for the Conviva analytics module
     *
     * **Note:** Configuration also required on {@link ContentEntryOptionsBuilder.withPlayConfigOverrides}
     *
     * @example
     *
     * ```js
     * // Create player config object configured with Conviva analytics
     * const config = PlayerConfigBuilder.forSetup()
     *     .withConviva({
     *         customerKey: '<YOUR-CUSTOMER-KEY>',
     *         applicationName: '<YOUR-BRAND-PLATFORM>'
     *     })
     *     .build()
     * ```
     *
     * @see {@link ConvivaAnalyticsConfig.applicationName}
     * @see {@link ConvivaAnalyticsConfig.customerKey}
     * @see {@link ConvivaAnalyticsConfig.touchstoneUrl}
     */
    SetupConfigBuilder.prototype.withConviva = function (conviva) {
        this._playConfigBuilder.withConviva(conviva);
        return this;
    };
    SetupConfigBuilder.prototype.withMux = function (muxConfig) {
        this._playConfigBuilder.withMux(muxConfig);
        return this;
    };
    // /**
    //  * Specify data to configure the captions handling.
    //  * @see {@link PlayerConfigCaptionsData}
    //  */
    // withCaptions(captions: DeepPartial<PlayerConfigCaptionsData>): this {
    //   this._playConfigBuilder.withCaptions(captions);
    //   return this;
    // }
    // /**
    //  * Specify data for the Comscore analytics module
    //  */
    // withComscore(comscore: ComscoreConfig): this {
    //   this._playConfigBuilder.withComscore(comscore);
    //   return this;
    // }
    // withOpenMeasurement(openMeasurement: OpenMeasurementConfig): this {
    //   // TODO:
    //   return this;
    // }
    // withIas(ias: IASAnalyticsConfig): this {
    //   // TODO:
    //   return this;
    // }
    /**
     * Specify data for ads
     */
    // withAds(ads: DeepPartial<PlayerConfigAdData>): this {
    //   // TODO:
    //   return this;
    // }
    /**
     * Specify data for CDN & DRM authentication
     */
    // withAuth(auth: DeepPartial<PlayerConfigAuthData>): this {
    //   // TODO:
    //   return this;
    // }
    /**
     * Specify data for DRM Playback
     */
    SetupConfigBuilder.prototype.withDrm = function (drm) {
        this._playConfigBuilder.withDrm(drm);
        return this;
    };
    /**
     * Specify data for controlling media playback
     */
    SetupConfigBuilder.prototype.withMedia = function (media) {
        this._playConfigBuilder.withMedia(media);
        return this;
    };
    /**
     * Specify configuration data related to all players
     */
    SetupConfigBuilder.prototype.withPlayback = function (playback) {
        this._playConfigBuilder.withPlayback(playback);
        return this;
    };
    /**
     * Specify configuration data for monitors
     */
    SetupConfigBuilder.prototype.withMonitors = function (monitors) {
        this._playConfigBuilder.withMonitors(monitors);
        return this;
    };
    /**
     * Specify additional player data
     */
    SetupConfigBuilder.prototype.withMetadata = function (metadata) {
        this._playConfigBuilder.withMetadata(metadata);
        return this;
    };
    // /**
    //  * Specify lifecycle configuration
    //  */
    SetupConfigBuilder.prototype.withLifecycle = function (lifecycle) {
        // TODO:
        this._playConfigBuilder.withLifecycle(lifecycle);
        return this;
    };
    /**
     * Specify data for the internal UI
     */
    SetupConfigBuilder.prototype.withUI = function (ui) {
        this._playConfigBuilder.withUI(ui);
        return this;
    };
    /**
     * Specify mock data
     */
    SetupConfigBuilder.prototype.withMocks = function (mocks) {
        this._playConfigBuilder.withMocks(mocks);
        return this;
    };
    /**
     * Specify data for debug mode
     */
    SetupConfigBuilder.prototype.withDebug = function (debug) {
        this._playConfigBuilder.withDebug(debug);
        return this;
    };
    SetupConfigBuilder.prototype.withTweaks = function (tweaks) {
        this._playConfigBuilder.withTweaks(tweaks);
        return this;
    };
    /**
     * Build the init config data
     */
    SetupConfigBuilder.prototype.build = function () {
        var playConfigData = this._playConfigBuilder.build();
        var hasPlayData = Object.keys(playConfigData).length > 0;
        if (hasPlayData) {
            this._setupConfigData.config = playConfigData;
        }
        return this._setupConfigData;
    };
    return SetupConfigBuilder;
}());
exports.SetupConfigBuilder = SetupConfigBuilder;
