"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConvivaKeys = exports.PlayerFriendlyObstructionPurpose = exports.SubjectToGDPROptions = exports.TCFVersion = exports.LifecycleState = exports.ContentProtectionType = exports.CmsProviderKey = exports.MediumVersion = exports.VideoEngineMetadataType = exports.PlayModeOptions = exports.AutoPlayPreloadBehavior = exports.ComponentCategory = exports.DRMDataSource = exports.AuthDrmTokenType = exports.AuthCdnTokenType = exports.ErrorSeverity = exports.CategoryCode = exports.DeviceType = exports.PlayerState = exports.AuthTokenType = exports.MediaState = exports.MediaJSONPlatform = exports.AdState = exports.AdBreakPosition = void 0;
/**
 * Describes where _(temporally)_ in `media` the `adBreak` will occur
 *
 * @see [[ISharedPlayer.getCurrentAdBreak]
 * @see [[AdBreakMetadata.position]]
 */
var AdBreakPosition;
(function (AdBreakPosition) {
    AdBreakPosition["Unknown"] = "unknown";
    /** Happens at the beginning of content, before media starts */
    AdBreakPosition["Preroll"] = "preroll";
    /** Happens in the middle of content, while media is ongoing */
    AdBreakPosition["Midroll"] = "midroll";
    /** Happens at the end of content, once media has finished */
    AdBreakPosition["Postroll"] = "postroll";
})(AdBreakPosition || (exports.AdBreakPosition = AdBreakPosition = {}));
var AdState;
(function (AdState) {
    AdState["Pending"] = "pending";
    AdState["Starting"] = "starting";
    AdState["Loaded"] = "loaded";
    AdState["Playing"] = "playing";
    AdState["Paused"] = "paused";
    AdState["Buffering"] = "buffering";
    AdState["Stopped"] = "stopped";
    AdState["Finished"] = "finished";
    AdState["Error"] = "error";
})(AdState || (exports.AdState = AdState = {}));
var MediaJSONPlatform;
(function (MediaJSONPlatform) {
    MediaJSONPlatform["TV"] = "tv";
    MediaJSONPlatform["Desktop"] = "desktop";
    MediaJSONPlatform["Tablet"] = "tablet";
    MediaJSONPlatform["Phone"] = "phone";
})(MediaJSONPlatform || (exports.MediaJSONPlatform = MediaJSONPlatform = {}));
/**
 * States of media playback
 */
var MediaState;
(function (MediaState) {
    MediaState["Pending"] = "pending";
    MediaState["Starting"] = "starting";
    MediaState["Loaded"] = "loaded";
    MediaState["Playing"] = "playing";
    MediaState["Paused"] = "paused";
    MediaState["Buffering"] = "buffering";
    MediaState["Seeking"] = "seeking";
    MediaState["Stopped"] = "stopped";
    MediaState["Finished"] = "finished";
    MediaState["Error"] = "error";
})(MediaState || (exports.MediaState = MediaState = {}));
var AuthTokenType;
(function (AuthTokenType) {
    AuthTokenType["Adobe"] = "adobe";
    AuthTokenType["JWS"] = "jws";
})(AuthTokenType || (exports.AuthTokenType = AuthTokenType = {}));
var PlayerState;
(function (PlayerState) {
    PlayerState["Pending"] = "playerPending";
    PlayerState["Initializing"] = "playerInitializing";
    PlayerState["Initialized"] = "playerInitialized";
    PlayerState["Attaching"] = "playerAttaching";
    PlayerState["Attached"] = "playerAttached";
    PlayerState["Listening"] = "playerListening";
    PlayerState["Detaching"] = "playerDetaching";
    PlayerState["Detached"] = "playerDetached";
    PlayerState["Destroying"] = "playerDestroying";
    PlayerState["Destroyed"] = "playerDestroyed";
    PlayerState["Error"] = "playerError";
})(PlayerState || (exports.PlayerState = PlayerState = {}));
/**
 * The category of device
 */
var DeviceType;
(function (DeviceType) {
    DeviceType["Android_Tv"] = "android_tv";
    DeviceType["Fire_Tv"] = "fire_tv";
    DeviceType["Tv_Os"] = "tv_os";
    DeviceType["Phone"] = "phone";
    DeviceType["Tablet"] = "tablet";
    DeviceType["Desktop"] = "desktop";
    DeviceType["Console"] = "console";
    DeviceType["Unknown"] = "unknown";
})(DeviceType || (exports.DeviceType = DeviceType = {}));
var CategoryCode;
(function (CategoryCode) {
    CategoryCode["Unspecified"] = "___";
    /** 000 - 099 are for generic error that apply across categories */
    CategoryCode["NetworkRequestFailure"] = "000";
    CategoryCode["ContentStalled"] = "001";
    /** 100 - 199 are for the Player category */
    CategoryCode["PlayerInitFailure"] = "100";
    /** 200 - 299 are for the View category */
    CategoryCode["ViewAttachFailure"] = "200";
    CategoryCode["ViewDetachFailure"] = "201";
    /** 300 - 399 are for the Transaction category */
    CategoryCode["TransactionInitFailure"] = "300";
    CategoryCode["TransactionStateTransitionFailure"] = "301";
    CategoryCode["TransactionComponentSetupFailure"] = "302";
    /** 400 - 499 are for the C-Config category */
    /** 500 - 599 are for the C-CMS category */
    CategoryCode["ContentResolutionFailure"] = "500";
    CategoryCode["ContentSelectionFailure"] = "501";
    CategoryCode["ContentSupportFailure"] = "502";
    /** 600 - 699 are for the C-Auth category */
    CategoryCode["CDNTokenFailure"] = "600";
    CategoryCode["DRMTokenFailure"] = "601";
    CategoryCode["DRMAssetMetadataFailure"] = "602";
    CategoryCode["TokenMismatch"] = "603";
    CategoryCode["MaxAllowedStreamsExceeded"] = "604";
    CategoryCode["MaxAllowedRenewalsExceeded"] = "605";
    /** 700 - 799 are for the C-Timeline category */
    /** 800 - 899 are for the C-Ads category */
    CategoryCode["AdPlaybackFailure"] = "800";
    CategoryCode["AdSessionTimeout"] = "801";
    CategoryCode["AdStall"] = "802";
    /** 900 - 999 are for the C-Media category */
    CategoryCode["MediaPlaybackFailure"] = "900";
    CategoryCode["MediaContentFailure"] = "901";
    CategoryCode["MediaNetworkFailure"] = "902";
    CategoryCode["MediaDRMFailure"] = "903";
    /** 1000 - 1099 are for the C-Captions category */
    CategoryCode["CaptionsStorageFailure"] = "1000";
    /** 1100 - 1199 are for the C-Analytics category */
    CategoryCode["AnalyticsDisabled"] = "1100";
    CategoryCode["AnalyticsConfigFailure"] = "1101";
    CategoryCode["AnalyticsOpenMeasurementSettingFailure"] = "1102";
})(CategoryCode || (exports.CategoryCode = CategoryCode = {}));
var ErrorSeverity;
(function (ErrorSeverity) {
    /**
     * This error is potentially recoverable by retrying.
     * For example, if the network is down, the client may attempt to
     * fetch the data again once the network is available.
     */
    ErrorSeverity["Error"] = "error";
    /**
     * This error is purely informative. The Player lifecycle can likely continue,
     * although with potential caveats or risks.
     */
    ErrorSeverity["Warn"] = "warn";
    /**
     * Errors of this nature always represent irrecoverable errors for the current playback session.
     * For any playback to a occur a new Play call would need to be made.
     */
    ErrorSeverity["Fatal"] = "fatal";
})(ErrorSeverity || (exports.ErrorSeverity = ErrorSeverity = {}));
/**
 * @see [[PlayerConfigAuthService.type]]
 */
var AuthCdnTokenType;
(function (AuthCdnTokenType) {
    AuthCdnTokenType["Spe"] = "spe";
})(AuthCdnTokenType || (exports.AuthCdnTokenType = AuthCdnTokenType = {}));
/**
 * @see [[PlayerConfigAuthDRMService.type]]
 */
var AuthDrmTokenType;
(function (AuthDrmTokenType) {
    AuthDrmTokenType["iSP"] = "isp";
})(AuthDrmTokenType || (exports.AuthDrmTokenType = AuthDrmTokenType = {}));
/**
 * Defines where to Source DRM asset details from
 *
 * @see [[PlayerDRMDataSourceConfig]]
 */
var DRMDataSource;
(function (DRMDataSource) {
    /**
     * Source DRM asset details from the stream manifest
     */
    DRMDataSource["Manifest"] = "manifest";
    /**
     * Source DRM asset details from a calculated merge between the
     * asset's `medium` data and player config `metadata`.
     *
     * **Note:** Config `metadata` takes precedence.
     */
    DRMDataSource["Metadata"] = "metadata";
})(DRMDataSource || (exports.DRMDataSource = DRMDataSource = {}));
var ComponentCategory;
(function (ComponentCategory) {
    ComponentCategory["Analytics"] = "analytics";
    ComponentCategory["Auth"] = "auth";
    ComponentCategory["AuthDRM"] = "authDRM";
    ComponentCategory["Captions"] = "captions";
    ComponentCategory["CMS"] = "cms";
    ComponentCategory["Cues"] = "cues";
    ComponentCategory["Lifecycle"] = "lifecycle";
    ComponentCategory["PlatformHelper"] = "platformHelper";
    ComponentCategory["UI"] = "ui";
    ComponentCategory["Video_Engine"] = "videoEngine";
    ComponentCategory["Mocks"] = "mocks";
    ComponentCategory["Custom"] = "custom";
})(ComponentCategory || (exports.ComponentCategory = ComponentCategory = {}));
/**
 * Available options for [[PlayerConfigAutoPlayOptionsData.preloadBehavior]]
 */
var AutoPlayPreloadBehavior;
(function (AutoPlayPreloadBehavior) {
    /**
     * Specifies that if autoplay is deemed blocked, that we should halt initialization of the
     * content _before_ initialization has completed.
     *
     * @example Scenario: Setting up content and waiting for user interaction, without an analytics session started.
     */
    AutoPlayPreloadBehavior["None"] = "none";
    /**
     * Specifies that if autoplay is deemed blocked, that we should halt our content phases
     * _after_ initialization has completed.
     */
    AutoPlayPreloadBehavior["Full"] = "full";
})(AutoPlayPreloadBehavior || (exports.AutoPlayPreloadBehavior = AutoPlayPreloadBehavior = {}));
/**
 * Available options for [[PlayerConfigPlaybackData.playMode]]
 */
var PlayModeOptions;
(function (PlayModeOptions) {
    /**
     * Allows web browser on mobile devices to stay inline and not
     * automatically go into [[Fullscreen]] mode _(iPhone tends to
     * do this by default)_
     */
    PlayModeOptions["Inline"] = "inline";
    /**
     * Let's web browser on mobile devices to automatically go
     * fullscreen mode once playback starts
     */
    PlayModeOptions["Fullscreen"] = "fullscreen";
})(PlayModeOptions || (exports.PlayModeOptions = PlayModeOptions = {}));
var VideoEngineMetadataType;
(function (VideoEngineMetadataType) {
    VideoEngineMetadataType["EMSG"] = "emsg";
    VideoEngineMetadataType["ID3"] = "id3";
    VideoEngineMetadataType["CUETAG"] = "cueTag";
    VideoEngineMetadataType["DATERANGE"] = "dateRange";
    VideoEngineMetadataType["CUSTOM"] = "custom";
    VideoEngineMetadataType["SCTE"] = "scte";
    VideoEngineMetadataType["CAST"] = "cast";
    VideoEngineMetadataType["EVENT_STREAM"] = "eventStream";
})(VideoEngineMetadataType || (exports.VideoEngineMetadataType = VideoEngineMetadataType = {}));
/**
 * Available versions of medium backend service _(media.json)_
 *
 * @see [[MediaJSONConfig.serviceUrl]]
 */
var MediumVersion;
(function (MediumVersion) {
    /**
     * Version of medium which does not require an `appId` to be specified
     */
    MediumVersion["V1"] = "v1";
    /**
     * Version of medium which requires an `appId` to be specified
     *
     * @see [[PlayerConfigMetadata.appId]]
     */
    MediumVersion["V2"] = "v2";
})(MediumVersion || (exports.MediumVersion = MediumVersion = {}));
/**
 * Keys of the available content management providers
 */
var CmsProviderKey;
(function (CmsProviderKey) {
    /** Key of the provider for retrieving content via url */
    CmsProviderKey["URL"] = "top.cms.provider.url";
    /** Key of the provider for retrieving content via medium backend service */
    CmsProviderKey["MediaJSON"] = "top.cms.provider.mediaJson";
})(CmsProviderKey || (exports.CmsProviderKey = CmsProviderKey = {}));
/** Available content protection types */
var ContentProtectionType;
(function (ContentProtectionType) {
    ContentProtectionType["Unknown"] = "unknown";
    ContentProtectionType["Preview"] = "preview";
    ContentProtectionType["PlayReady"] = "playready";
    ContentProtectionType["MultiDRM"] = "multidrm";
    ContentProtectionType["Fairplay"] = "fairplay";
    ContentProtectionType["Primetime"] = "primetime";
    ContentProtectionType["Widevine"] = "widevine";
    ContentProtectionType["BulkAES"] = "bulkaes";
    ContentProtectionType["Unprotected"] = "unprotected";
})(ContentProtectionType || (exports.ContentProtectionType = ContentProtectionType = {}));
/**
 * Defines the behavior of the player for a `LifecycleEvent`
 *
 * @see [[LifecycleEvent]]
 * @see [[PlayerConfigLifecycleData]]
 * @see [[PlayerEventType.Lifecycle_State_Changed]]
 */
var LifecycleState;
(function (LifecycleState) {
    /** Player will continue playback when player changes to a mode configured to continue */
    LifecycleState["Continue"] = "continue";
    /** Player will pause playback when player changes to a mode configured to pause */
    LifecycleState["Pause"] = "pause";
    /** Player will stop playback and be destroyed when player changes to a mode configured to unload */
    LifecycleState["Unload"] = "unload";
})(LifecycleState || (exports.LifecycleState = LifecycleState = {}));
/**
 * Available versions of the consent framework
 *
 * @see [[GDPRFeatureData.consentFrameworkVersion]]
 */
var TCFVersion;
(function (TCFVersion) {
    /** Version 1 of the TCF */
    TCFVersion["V1"] = "v1";
    /** Version 2 of the TCF */
    TCFVersion["V2"] = "v2";
})(TCFVersion || (exports.TCFVersion = TCFVersion = {}));
/**
 * Possible values indicating whether the current environment
 * is subject to GDPR
 *
 * @see [[GDPRConsentData.subjectToGDPR]]
 */
var SubjectToGDPROptions;
(function (SubjectToGDPROptions) {
    /** Is subject to GDPR rules */
    SubjectToGDPROptions["Yes"] = "1";
    /** Is not subject to GDPR rules */
    SubjectToGDPROptions["No"] = "0";
    /** Whether subject to GDPR is undetermined */
    SubjectToGDPROptions["Unknown"] = "-1";
})(SubjectToGDPROptions || (exports.SubjectToGDPROptions = SubjectToGDPROptions = {}));
var PlayerFriendlyObstructionPurpose;
(function (PlayerFriendlyObstructionPurpose) {
    PlayerFriendlyObstructionPurpose["MediaControls"] = "mediaControls";
    PlayerFriendlyObstructionPurpose["CloseAd"] = "closeAd";
    PlayerFriendlyObstructionPurpose["NotVisible"] = "notVisible";
    PlayerFriendlyObstructionPurpose["Other"] = "other";
})(PlayerFriendlyObstructionPurpose || (exports.PlayerFriendlyObstructionPurpose = PlayerFriendlyObstructionPurpose = {}));
var ConvivaKeys;
(function (ConvivaKeys) {
    ConvivaKeys["APPLICATION_VERSION"] = "c3.app.version";
    ConvivaKeys["ASSET_NAME"] = "Conviva.assetName";
    ConvivaKeys["CUSTOMER_KEY"] = "customerKey";
    ConvivaKeys["DEFAULT_RESOURCE"] = "Conviva.defaultResource";
    ConvivaKeys["DURATION"] = "Conviva.duration";
    ConvivaKeys["ENCODED_FRAMERATE"] = "Conviva.encodedFrameRate";
    ConvivaKeys["FRAMEWORK_NAME"] = "Conviva.frameworkName";
    ConvivaKeys["FRAMEWORK_VERSION"] = "Conviva.frameworkVersion";
    ConvivaKeys["IS_LIVE"] = "Conviva.streamType";
    ConvivaKeys["MANUFACTURER"] = "DeviceManufacturer";
    ConvivaKeys["PLAYER_NAME"] = "Conviva.applicationName";
    ConvivaKeys["POD_DURATION"] = "podDuration";
    ConvivaKeys["POD_INDEX"] = "podIndex";
    ConvivaKeys["POD_POSITION"] = "podPosition";
    ConvivaKeys["STREAM_URL"] = "Conviva.streamUrl";
    ConvivaKeys["VIEWER_ID"] = "Conviva.viewerId";
    // DeviceMetadata
    ConvivaKeys["BRAND"] = "DeviceBrand";
    ConvivaKeys["CATEGORY"] = "DeviceCategory";
    ConvivaKeys["MODEL"] = "DeviceModel";
    ConvivaKeys["OS_NAME"] = "OperatingSystemName";
    ConvivaKeys["OS_VERSION"] = "OperatingSystemVersion";
    ConvivaKeys["SCREEN_RESOLUTION_HEIGHT"] = "ScreenHeight";
    ConvivaKeys["SCREEN_RESOLUTION_SCALE_FACTOR"] = "ScaleFactor";
    ConvivaKeys["SCREEN_RESOLUTION_WIDTH"] = "ScreenWidth";
    ConvivaKeys["TYPE"] = "DeviceType";
    ConvivaKeys["VERSION"] = "DeviceVersion";
    // CallbackFunctions
    ConvivaKeys["CONSOLE_LOG"] = "consoleLog";
    ConvivaKeys["CREATE_TIMER"] = "createTimer";
    ConvivaKeys["GET_EPOCH_TIME_IN_MS"] = "getEpochTimeInMs";
    ConvivaKeys["LOAD_DATA"] = "loadData";
    ConvivaKeys["MAKE_REQUEST"] = "makeRequest";
    ConvivaKeys["SAVE_DATA"] = "saveData";
    // Init options
    ConvivaKeys["LOG_LEVEL"] = "logLevel";
    ConvivaKeys["GATEWAY_URL"] = "gatewayUrl";
    // Playback
    ConvivaKeys["BITRATE"] = "BITRATE";
    ConvivaKeys["AVG_BITRATE"] = "AVG_BITRATE";
    ConvivaKeys["BUFFER_LENGTH"] = "BUFFER_LENGTH";
    ConvivaKeys["CDN_IP"] = "CDN_IP";
    ConvivaKeys["PLAYER_STATE"] = "PLAYER_STATE";
    ConvivaKeys["PLAY_HEAD_TIME"] = "PLAY_HEAD_TIME";
    ConvivaKeys["RENDERED_FRAMERATE"] = "RENDERED_FRAMERATE";
    ConvivaKeys["RESOLUTION"] = "RESOLUTION";
    ConvivaKeys["SEEK_ENDED"] = "SEEK_ENDED";
    ConvivaKeys["SEEK_STARTED"] = "SEEK_STARTED";
    ConvivaKeys["AUDIO_LANGUAGE"] = "AUDIO_LANGUAGE";
    ConvivaKeys["SUBTITLES_LANGUAGE"] = "SUBTITLES_LANGUAGE";
    ConvivaKeys["CLOSED_CAPTIONS_LANGUAGE"] = "CLOSED_CAPTIONS_LANGUAGE";
    // PlayerState
    ConvivaKeys["BUFFERING"] = "BUFFERING";
    ConvivaKeys["NOT_MONITORED"] = "NOT_MONITORED";
    ConvivaKeys["PAUSED"] = "PAUSED";
    ConvivaKeys["PLAYING"] = "PLAYING";
    ConvivaKeys["STOPPED"] = "STOPPED";
    ConvivaKeys["UNKNOWN"] = "UNKNOWN";
    // StreamType
    ConvivaKeys["VOD"] = "VOD";
    ConvivaKeys["LIVE"] = "LIVE";
    // Device Categories
    ConvivaKeys["CAT_ANDROID_DEVICE"] = "AND";
    ConvivaKeys["CAT_APPLE_DEVICE"] = "APL";
    ConvivaKeys["CAT_CHROMECAST"] = "CHR";
    ConvivaKeys["CAT_DESKTOP_APP"] = "DSKAPP";
    ConvivaKeys["CAT_DEVICE_SIMULATOR"] = "SIMULATOR";
    ConvivaKeys["CAT_KAIOS_DEVICE"] = "KAIOS";
    ConvivaKeys["CAT_LG_TV"] = "LGTV";
    ConvivaKeys["CAT_NINTENDO"] = "NINTENDO";
    ConvivaKeys["CAT_PLAYSTATION"] = "PS";
    ConvivaKeys["CAT_ROKU"] = "RK";
    ConvivaKeys["CAT_SAMSUNG_TV"] = "SAMSUNGTV";
    ConvivaKeys["CAT_SET_TOP_BOX"] = "STB";
    ConvivaKeys["CAT_SMART_TV"] = "TV";
    ConvivaKeys["CAT_TIVO"] = "TIVO";
    ConvivaKeys["CAT_WEB"] = "WEB";
    ConvivaKeys["CAT_WINDOWS_DEVICE"] = "WIN";
    ConvivaKeys["CAT_XBOX"] = "XB";
    // Device Metadata Keys
    ConvivaKeys["META_BRAND"] = "DeviceBrand";
    ConvivaKeys["META_CATEGORY"] = "DeviceCategory";
    ConvivaKeys["META_MANUFACTURER"] = "DeviceManufacturer";
    ConvivaKeys["META_MODEL"] = "DeviceModel";
    ConvivaKeys["META_OS_NAME"] = "OperatingSystemName";
    ConvivaKeys["META_OS_VERSION"] = "OperatingSystemVersion";
    ConvivaKeys["META_TYPE"] = "DeviceType";
    ConvivaKeys["META_VERSION"] = "DeviceVersion";
    // DeviceType
    ConvivaKeys["TYPE_CONSOLE"] = "Console";
    ConvivaKeys["TYPE_DESKTOP"] = "DESKTOP";
    ConvivaKeys["TYPE_MOBILE"] = "Mobile";
    ConvivaKeys["TYPE_SETTOP"] = "Settop";
    ConvivaKeys["TYPE_SMARTTV"] = "SmartTV";
    ConvivaKeys["TYPE_TABLET"] = "Tablet";
    // setAdListener info
    ConvivaKeys["AD_PRELOAD_FEATURE"] = "adPreloading";
    ConvivaKeys["AD_TAG_URL"] = "adTagUrl";
    ConvivaKeys["IMASDK_CONTENT_PLAYER"] = "imaMainContentPlayer";
    // setPlayer extraListeners
    ConvivaKeys["CONVIVA_AD_MODULE"] = "convivaAdModule";
    ConvivaKeys["CONVIVA_MODULE"] = "convivaModule";
    ConvivaKeys["MEDIA_ELEMENT"] = "mediaElement";
})(ConvivaKeys || (exports.ConvivaKeys = ConvivaKeys = {}));
