"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Size = exports.Edge = exports.FontType = exports.Opacity = exports.Color = void 0;
/**
 * Color options for BMP subtitle settings
 */
var Color;
(function (Color) {
    Color["Default"] = "null";
    Color["White"] = "white";
    Color["Black"] = "black";
    Color["Red"] = "red";
    Color["Green"] = "green";
    Color["Blue"] = "blue";
    Color["Cyan"] = "cyan";
    Color["Yellow"] = "yellow";
    Color["Magenta"] = "magenta";
})(Color || (exports.Color = Color = {}));
/**
 * Values available for caption opacities
 */
var Opacity;
(function (Opacity) {
    Opacity["Default"] = "null";
    Opacity["Opacity_0"] = "0";
    Opacity["Opacity_25"] = "25";
    Opacity["Opacity_50"] = "50";
    Opacity["Opacity_75"] = "75";
    Opacity["Opacity_100"] = "100";
})(Opacity || (exports.Opacity = Opacity = {}));
/**
 * Font family options for BMP subtitle settings
 */
var FontType;
(function (FontType) {
    FontType["Default"] = "null";
    FontType["Monospaced_With_Serifs"] = "monospacedserif";
    FontType["Proportional_With_Serifs"] = "proportionalserif";
    FontType["Monospaced_Without_Serifs"] = "monospacedsansserif";
    FontType["Proportional_Without_Serifs"] = "proportionalsansserif";
    FontType["Casual"] = "casual";
    FontType["Cursive"] = "cursive";
    FontType["Small_Capitals"] = "smallcapital";
})(FontType || (exports.FontType = FontType = {}));
/**
 * Values available for caption edges
 */
var Edge;
(function (Edge) {
    Edge["Default"] = "null";
    Edge["Raised"] = "raised";
    Edge["Depressed"] = "depressed";
    Edge["Uniform"] = "uniform";
    Edge["Shadow"] = "dropshadowed";
})(Edge || (exports.Edge = Edge = {}));
/**
 * Font size % options for BMP subtitle settings
 */
var Size;
(function (Size) {
    Size["Default"] = "null";
    Size["Size_50"] = "50";
    Size["Size_75"] = "75";
    Size["Size_100"] = "100";
    Size["Size_150"] = "150";
    Size["Size_200"] = "200";
    Size["Size_300"] = "300";
    Size["Size_400"] = "400";
})(Size || (exports.Size = Size = {}));
