import { videoSessionStore } from 'apolloGraphql/mml-apollo-cache';
import { VideoProperties, VideoPropertyType } from 'state-machines/analytic-states';
import { DEFAULT_LIVE_PLAYER_STATE, LivePlayerState } from 'state-machines/live-player-states';
import { DeepPartial } from 'utility-types';
import { VideoAdsState, VIDEO_ADS_INITIAL_STATE } from './video-ads-state';
import deeperMerge from 'utils/deepMerge';
import { useReactiveVar } from '@apollo/client';

/**
 * The different types of video players.  Add to this enum as necessary.
 */
export enum SessionName {
  LeftPlayer = 'leftPlayer',
  RightPlayer = 'rightPlayer',
  AdPlayer = 'adPlayer',
}

type IAllSession = {
  [key in SessionName]?: VideoSession;
};
interface IAllSessionWithPrimary extends IAllSession {
  [SessionName.LeftPlayer]: VideoSession;
}

export interface VideoSessionState {
  primarySession: SessionName;
  sessions: IAllSessionWithPrimary;
}

export interface VideoSession {
  livePlayerState: LivePlayerState;
  videoAds: VideoAdsState;
  videoProperties: VideoPropertyType;
}

export const INITIAL_VIDEO_SESSION = Object.freeze<VideoSession>({
  livePlayerState: DEFAULT_LIVE_PLAYER_STATE,
  videoProperties: VideoProperties,
  videoAds: VIDEO_ADS_INITIAL_STATE,
});

export const INITIAL_VIDEO_SESSION_STATE = Object.freeze<VideoSessionState>({
  primarySession: SessionName.LeftPlayer,
  sessions: { [SessionName.LeftPlayer]: INITIAL_VIDEO_SESSION },
});

export function updateVideoSession(
  params: VideoSession,
  shouldMerge: false,
  sessionName?: SessionName,
): VideoSessionState;
export function updateVideoSession(
  params: DeepPartial<VideoSession>,
  shouldMerge?: true,
  sessionName?: SessionName,
): VideoSessionState;
export function updateVideoSession(
  params: DeepPartial<VideoSession> | VideoSession,
  shouldMerge = true,
  sessionName?: SessionName,
) {
  // TODO:  this is a bit expensive due to the merging and spread operators
  const videoSession = videoSessionStore();
  const targetSessionName = sessionName ?? videoSession.primarySession;
  const targetSession = videoSession.sessions[targetSessionName];

  return videoSessionStore({
    ...videoSession,
    sessions: {
      ...videoSession.sessions,
      [targetSessionName]: shouldMerge && targetSession ? deeperMerge(targetSession, params) : params,
    },
  });
}

export function updatePrimarySession(sessionName: SessionName) {
  return videoSessionStore({
    ...videoSessionStore(),
    primarySession: sessionName,
  });
}

export function resetPrimarySessionToLeftSide() {
  const videoSession = videoSessionStore();

  const primarySession = videoSession.sessions[videoSession.primarySession];

  if (!primarySession) throw new Error('Something went wrong.  There is no primary session in the videoSessionStore');

  return videoSessionStore({
    primarySession: SessionName.LeftPlayer,
    sessions: {
      [SessionName.LeftPlayer]: primarySession,
    },
  });
}

export function initNewVideoSession(sessionName: SessionName) {
  const videoSession = videoSessionStore();

  // if session already exists, just return it
  if (videoSession.sessions[sessionName]) return videoSessionStore();

  return videoSessionStore({
    ...videoSession,
    sessions: {
      ...videoSession.sessions,
      [sessionName]: INITIAL_VIDEO_SESSION,
    },
  });
}

export function usePrimarySession() {
  const sessionState = useReactiveVar(videoSessionStore);

  return sessionState.sessions[sessionState.primarySession];
}
